<template>
  <div class="flex justify-center flex-col items-center">
    <div
      class="bg-[url('https://ik.imagekit.io/xposuremusic/industry-pros-header-new_dz7q2vN4d.png?updatedAt=1699347578632')] w-full bg-no-repeat text-center flex flex-col h-[530px] mt-12 absolute top-0"
      style="background-position-x: center"
    ></div>

    <div
      class="font-druk-wide text-4xl italic text-grayscale-96 uppercase mt-28 mb-5 z-10 text-center"
    >
      Network & grow
    </div>
    <div
      class="font-montserrat font-bold text-grayscale-96 uppercase z-10 text-center"
    >
      Elevate your music career with advice from top industry pros
    </div>

    <div
      class="z-10 grid gap-4 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-center mt-10 mb-12 lg:mb-28 w-auto sm:w-5/6 max-w-[360px] lg:max-w-4xl xl:max-w-1120px"
    >
      <IndustryProsHeaderCard
        emoji="🎧"
        title="Expert Feedback"
        description="Get feedback, mentorship, and 1-1 video calls."
        @click="scrollToList"
        fixed-height="36"
      />
      <IndustryProsHeaderCard
        emoji="💫"
        title="Artist Development"
        description="Level up with insights and guidance."
        @click="scrollToList"
        fixed-height="36"
      />
      <IndustryProsHeaderCard
        emoji="🪢"
        title="Networking"
        description="Build valuable connections in the industry."
        @click="scrollToList"
        fixed-height="36"
      />
      <IndustryProsHeaderCard
        emoji="🛡"
        title="Curated Pros"
        description="Each Pro is handpicked and verified by our team."
        @click="scrollToList"
        fixed-height="36"
      />
    </div>

    <div id="search-bar" class="flex w-full justify-center">
      <div
        class="w-full flex md:hidden flex-col gap-0 px-8 justify-center content-center items-center w-auto"
      >
        <div class="flex flex-col gap-0 relative bg-black h-24 lg:h-12 w-full">
          <input-field
            name="search"
            type="search"
            :value="search"
            @update:value="searchChanged"
            placeholder="Search by Name, Label, Artist..."
            class="lg:min-w-[413px] border border-grayscale-20 h-12 items-center grow"
            input-container-class="h-full"
          />

          <div class="flex justify-between h-12">
            <button
              class="flex gap-4 px-6 py-2 border border-grayscale-20 h-full items-center grow"
              @click="showFiltersChanged()"
            >
              <i class="icon-filter-new text-grayscale-96 text-20 leading-5" />
              <div class="flex items-center gap-1 text-sm">
                <p class="font-semibold leading-160n text-grayscale-96">
                  Genre & Role
                </p>
                <i class="icon-down text-[14px] pl-1" />
              </div>
            </button>
            <button
              class="border border-grayscale-20 h-full w-12"
              @click="showOrdersChanged()"
            >
              <i
                :class="[
                  query.order && query.column
                    ? 'text-accent-orange'
                    : 'text-grayscale-96',
                  'icon-arrow-sort text-20 leading-5',
                ]"
              />
            </button>
          </div>
        </div>
      </div>
      <div
        class="w-full hidden md:flex flex-col gap-6 px-8 lg:px-0 justify-center content-center items-center w-auto sm:w-5/6 md:max-w-xl lg:max-w-4xl xl:max-w-1120px"
      >
        <div
          class="flex flex-col lg:flex-row gap-8 lg:gap-0 lg:items-center relative bg-black h-36 lg:h-12 w-full"
        >
          <button
            class="flex gap-4 px-6 py-2 border border-grayscale-20 h-12 items-center"
            @click="showFiltersChanged()"
          >
            <i class="icon-filter-new text-grayscale-96 text-20 leading-5" />
            <div class="flex items-center gap-1 text-sm">
              <p class="font-semibold leading-160n text-grayscale-96">
                Genre & Role
              </p>
              <i class="icon-down text-[14px] pl-1" />
            </div>
          </button>

          <input-field
            name="search"
            type="search"
            :value="search"
            @update:value="searchChanged"
            placeholder="Search by Name, Label, Artist..."
            class="lg:min-w-[413px] border border-grayscale-20 h-12 items-center grow"
            input-container-class="h-full"
          />

          <button
            class="border border-grayscale-20 h-full w-12"
            @click="showOrdersChanged()"
          >
            <i
              :class="[
                query.order && query.column
                  ? 'text-accent-orange'
                  : 'text-grayscale-96',
                'icon-arrow-sort text-20 leading-5',
              ]"
            />
          </button>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col pt-8 pb-28 px-8 lg:px-0 gap-6 w-auto sm:w-5/6 md:max-w-xl lg:max-w-4xl xl:max-w-1120px flex-grow"
    >
      <div
        v-if="displayedJobs?.length || displayedGenres?.length"
        class="w-full flex flex-wrap gap-[5px] z-10"
      >
        <template v-if="displayedJobs?.length">
          <p
            v-for="filter in displayedJobs"
            :key="filter"
            class="px-2.5 py-1 flex gap-[2px] items-center bg-grayscale-16 border border-grayscale-20 rounded-2"
          >
            <span class="text-xs leading-140 text-grayscale-96">{{
              filter
            }}</span>
            <button @click="removeFilter(true, filter.toString())" class="flex">
              <i class="icon-close text-grascale-96 text-20 leading-5" />
            </button>
          </p>
        </template>
        <template v-if="displayedGenres?.length">
          <p
            v-for="filter in displayedGenres"
            :key="filter"
            class="px-2.5 py-1 flex gap-[2px] items-center bg-grayscale-16 border border-grayscale-20 rounded-2"
          >
            <span class="text-xs leading-140 text-grayscale-96">{{
              filter
            }}</span>
            <button
              @click="removeFilter(false, filter.toString())"
              class="flex"
            >
              <i class="icon-close text-grascale-96 text-20 leading-5" />
            </button>
          </p>
        </template>
      </div>
      <div class="min-h-60 flex flex-col gap-4">
        <div
          name="list"
          tag="container"
          class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-x-6 gap-y-12"
        >
          <Pro v-for="(pro, index) in pros" :key="index" :pro="pro" />
          <XposureLoader
            v-if="pending"
            additionalClasses="fixed top-0 left-0 w-full h-full z-50"
            spinnerAdditionalClasses="fixed top-[50vh]"
            showBackground
          />
        </div>
        <div
          v-if="!pros.length"
          class="border border-grayscale-16 bg-grayscale-7 rounded-8 p-8 flex flex-col items-center gap-1"
        >
          <p>No Pros Were Found</p>
          <p class="text-grayscale-80 text-xs text-center">
            Would you like to see someone on Xposure? We're always on the
            lookout for fresh talent to feature.
          </p>
          <a
            class="text-accent-orange mt-7"
            href="https://info.xposuremusic.com/suggest-a-professional"
            >Suggest a Pro</a
          >
        </div>
      </div>
    </div>
  </div>
  <Footer />
  <filters-modal v-if="showFilters" @close="showFiltersChanged()">
  </filters-modal>

  <sort-modal v-if="showOrders" @close="showOrders = false"></sort-modal>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useProStore } from "@/store/pros";
import Pro from "@/components/Pro.vue";
import InputField from "@/components/layout/inputs/InputField.vue";
import { debounce, scrollToTargetAdjusted } from "@/utils/helpers";
import AnalyticsService from "@/services/analytics-service";
import PageTitles from "@/assets/strings";
import FiltersModal from "@/components/modal/FiltersModal.vue";
import SortModal from "@/components/modal/SortModal.vue";
import XposureLoader from "@/components/loader/XposureLoader.vue";
import Footer from "@/components/footers/Footer.vue";
import IndustryProsHeaderCard from "@/components/industry-pros/IndustryProsHeaderCard.vue";

const router = useRouter();

useHead({
  title: PageTitles.industryPros.title,
  meta: [
    {
      name: "description",
      content: PageTitles.industryPros.description,
    },
    {
      name: "og:description",
      content: PageTitles.industryPros.description,
    },
    {
      name: "twitter:description",
      content: PageTitles.industryPros.description,
    },
    {
      property: "og:title",
      content: PageTitles.industryPros.title,
    },
    {
      property: "twitter:title",
      content: PageTitles.industryPros.title,
    },
  ],
});

const proStore = useProStore();
const analyticsService = new AnalyticsService("analytics");
analyticsService.trackEvent("Pro List Viewed", {});

const pros = computed(() => {
  return proStore.pros;
});

watch(
  () => proStore.pros,
  () => {
    trackFiltered();
  },
);

const query = ref(router.currentRoute.value.query);

watch(
  () => router.currentRoute.value.query,
  (val) => {
    if (router.currentRoute.value.path === "/industry-pros") {
      query.value = val;
    }
  },
);

const displayedJobs = computed(() =>
  query.value.job?.length ? query.value.job.toString().split(",") : "",
);
const displayedGenres = computed(() =>
  query.value.genre?.length ? query.value.genre.toString().split(",") : "",
);
const sortingApplied = computed(() =>
  query.value.column?.toString().split(","),
);
const sortingDirectionApplied = computed(() =>
  query.value.order?.toString().split(","),
);

const showFilters = ref(false);
const showOrders = ref(false);

const search = ref(query.value.search || "");
const searchError = ref("");

const scrollToList = () => {
  scrollToTargetAdjusted("search-bar", 60);
};

function setSearch(value: string) {
  search.value = value;
  router.replace({
    query: {
      ...router.currentRoute.value.query,
      search: value,
    },
  });
}

const searchChanged = debounce(setSearch, 500);

const trackFiltered = () => {
  const filters = [];
  if (displayedJobs.value)
    filters.push({ type: "jobs", values: displayedJobs.value });
  if (displayedGenres.value)
    filters.push({ type: "genres", values: displayedGenres.value });
  const sorts = sortingApplied.value
    ? [{ type: sortingApplied.value, direction: sortingDirectionApplied.value }]
    : [];
  if (filters.length || sorts.length) {
    analyticsService.trackEvent("Pro List Filtered", { filters, sorts });
  }
};
trackFiltered();

function showFiltersChanged(): void {
  showFilters.value = !showFilters.value;
}

function showOrdersChanged(): void {
  showOrders.value = !showOrders.value;
}

function removeFilter(isJob: boolean, name: string): void {
  const newJobs = ref(displayedJobs.value);
  const newGenres = ref(displayedGenres.value);
  if (isJob) {
    newJobs.value = displayedJobs.value!.filter((job) => job !== name);
  } else {
    newGenres.value = displayedGenres.value!.filter((genre) => genre !== name);
  }

  router.replace({
    query: {
      ...router.currentRoute.value.query,
      job: newJobs.value?.toString() || [],
      genre: newGenres.value?.toString() || [],
    },
  });
}

let pending = ref(false);

proStore
  .fetchPros(query)
  .then(({ pending: asd }) => {
    pending = asd;
  })
  .catch(() => {
    console.log("not gooood");
  });
</script>

<style scoped>
/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

input[type="radio"]:checked + label span {
  background-color: #f5f5f5;
  box-shadow: 0px 0px 0px 4px #1e1e21 inset;
  border: 1px #f5f5f5 solid;
}
</style>
