<template>
  <sample-modal
    overlay-class="modal-opacity-bg overflow-hidden"
    container-class="md:rounded-20 max-h-fill sm-h-fill md:h-1/3 overflow-hidden flex flex-col"
    @close-modal="closeModal()"
  >
    <template #main>
      <div
        class="relative md:w-full w-screen sm-h-fill md:h-1/3 p-4 flex flex-col bg-woodsmoke1 justify-start text-14 flex-1 pb-24 md:pb-4 overflow-auto"
      >
        <button
          id="close-button"
          class="cursor-pointer w-6 h-6 flex items-center justify-center ml-auto absolute top-4 right-4"
          @click="closeModal"
        >
          <i class="icon-close text-24 text-white" />
        </button>

        <div :class="`${containerClass} flex flex-col gap-10 mt-10 pl-4`">
          <div class="flex flex-col gap-4">
            <p
              class="uppercase text-grayscale-80 font-semibold text-13-140 text-start"
            >
              Genres
            </p>

            <checkbox
              filter="genres"
              :value="genre"
              v-model:selected="selectedFilters.genre[genre]"
              v-for="(genre, index) in modalGenres"
              :key="index"
              @selectionChanged:selected="updateFilterObject('genre', genre)"
            />
          </div>

          <div class="flex flex-col gap-4">
            <p
              class="uppercase text-grayscale-80 font-semibold text-13-140 text-start"
            >
              Roles
            </p>

            <checkbox
              filter="roles"
              :value="role"
              v-model:selected="selectedFilters.job[role]"
              v-for="(role, index) in modalJobs"
              :key="index"
              @selectionChanged:selected="updateFilterObject('job', role)"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="w-full grid grid-cols-2 justify-center items-center gap-6 p-4 border-t border-grayscale-16 px-4 shrink-0 fixed bottom-0 left-0 md:static bg-grayscale-7"
      >
        <general-button @click="closeModal" color="light-gray">
          Cancel</general-button
        >
        <general-button @click="filtersChanged"
          ><span class="sm:hidden">Apply</span
          ><span class="hidden sm:block">View Results</span>
        </general-button>
      </div>
    </template>
  </sample-modal>
</template>

<script setup lang="ts">
import SampleModal from "@/components/modal/SampleModal.vue";
import { computed } from "vue";
import GeneralButton from "@/components/layout/buttons/GeneralButton.vue";
import Checkbox from "@/components/layout/inputs/Checkbox.vue";
import { Genre, ProJobTitle } from "@/types/user";

const emit = defineEmits(["close"]);
const router = useRouter();
const modalJobs = Object.keys(ProJobTitle);
const modalGenres = Object.keys(Genre);
const query = computed(() => router.currentRoute.value.query);
const selectedFilters = ref({
  job: modalJobs.reduce((o, key) => ({ [key]: false, ...o }), {}) as {
    [key in string]: boolean;
  },
  genre: modalGenres.reduce((o, key) => ({ ...o, [key]: false }), {}) as {
    [key in string]: boolean;
  },
});

const filtersChanged = () => {
  const jobs = Object.entries(selectedFilters.value.job)
    .filter(([_key, value]) => value)
    .map((num) => num[0]);
  const genres = Object.entries(selectedFilters.value.genre)
    .filter(([_key, value]) => value)
    .map((num) => num[0]);
  router.replace({
    query: {
      ...router.currentRoute.value.query,
      job: jobs.length > 1 ? jobs.toString() : jobs,
      genre: genres.length > 1 ? genres.toString() : genres,
    },
  });
  closeModal();
};

const updateFilterObject = (filter: "job" | "genre", value: string) => {
  for (const key in selectedFilters.value[filter]) {
    if (value.includes(key)) {
      selectedFilters.value[filter][key] = !selectedFilters.value[filter][key];
    }
  }
};

for (const key in query.value) {
  updateFilterObject(key as "job" | "genre", query.value[key] as string);
}

const userAgent = navigator.userAgent || navigator.vendor;
const containerClass = computed(() => {
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    const chromeAgent =
      userAgent.indexOf("Chrome") > -1 || /Google Inc/.test(navigator.vendor);
    let safariAgent =
      userAgent.indexOf("Safari") > -1 &&
      /Apple Computer/.test(navigator.vendor);
    if (chromeAgent && safariAgent) safariAgent = false;
    return safariAgent ? "mb-24" : "";
  } else {
    return "";
  }
});

const closeModal = () => emit("close");
</script>
